import React from 'react';
import { Tabs, SafeArea } from 'antd-mobile'
import DocumentTitle from 'react-document-title';

import './index.less';
import AssessFaqItem from './faqItem';

const AssessFAQPage = () => {
  const tabList = [
    {
      title: "肩部",
      faqs: [
        {
          title: "什么是溜肩？",
          mediaList: [
            {
              text: "溜肩是指人体肩部与颈部的角度较大，如果≤15度就是平肩，≥20度 就是溜肩。形成主要因素还是经常趴在桌子上 ，伏案的姿势过久，导致上斜方肌紧张。体态上的表现：即使四肢很纤细，但是因为溜肩显得背部很 厚，脸大 身子小。穿衣服撑不起来，背个单肩包，肩带动不动就往下滑。",
              imageUrl: "https://syh-img.shareyourhealth.cn/1syh_assess_faq_liujian.png",
            },
          ]
        }, {
          title: "什么是圆肩驼背？",
          mediaList: [
            {
              text: "圆肩也叫含胸，有点像缩着肩膀来遮一遮胸。其实是指双肩向前弯曲向内扣，胸部内缩，并伴有肩胛骨前伸，肩部这块儿形成一个半圆弧形。这种体态长期会出现颈腰椎疼痛、后背疼痛，肩膀活动受限等危害。",
              imageUrl: "https://syh-img.shareyourhealth.cn/2syh_assess_faq_yuanjian.png",
            }
          ]
        }, {
          title: "什么是翼状肩胛？",
          mediaList: [
            {
              text: "翼状肩胛骨在女生中比较常见，表现上肩胛骨内侧缘会翘起，手臂后伸翘起的更明显了。这种姿态主要的原因是长期伏案导致圆肩，圆肩导致背部肌肉长期被拉长，从而引发肩胛骨周围肌力不平衡，导致翼状肩胛。长期这种姿态可能会引发很多问题，可能导致上背疼痛，肩关节活动受限、肩部紧张、颈部不适等等。",
              imageUrl: "https://syh-img.shareyourhealth.cn/3syh_assess_faq_yizhuangjian.png",
            }
          ]
        }, {
          title: "什么是高低肩？",
          mediaList: [
            {
              text: "一侧肩胛骨始终保持在上抬位，另一侧肩胛始终处于下降位的体态。高低肩首先要注意你平时是否有背单肩包的习惯？ 你是否惯用右侧身体？你的站姿坐姿是否会明显倾斜？这些不对称的习惯会增加高低肩的发生率。如果是左肩高右肩低，很可能是左侧斜方肌、斜角肌紧张，右侧腰方肌紧张。左右相反的话同理。",
              imageUrl: "https://syh-img.shareyourhealth.cn/4syh_assess_faq_gaodijian.png",
            }
          ]
        }
      ],
    }, {
      title: "颈椎",
      faqs: [
        {
          title: "什么是头前伸？",
          mediaList: [
            {
              text: "从侧面看时耳垂位置处于肩膀前方的体态。头前伸除了让你的上半身气质变差，还会对颈椎造成颈椎曲度变直或者反弓。如果压迫到神经，还会导致手麻头晕。导致的原因主要是习惯性长期低头看数据看电脑盯手机，一旦你开始低头，颈部承担的压力就会成指数增长，导致脖子后侧肌群弱，胸部和肩部的肌群紧张。导致颈部前后肌力不平衡。",
              imageUrl: "https://syh-img.shareyourhealth.cn/5syh_assess_faq_touqianshen.png",
            }
          ]
        }
      ],
    }, {
      title: "腰胸椎",
      faqs: [
        {
          title: "什么是腰椎前凸过度？",
          mediaList: [
            {
              text: "腰椎前凸体态，简单来说就是指下背部过度向后弯曲、腹部向前顶出去超出一定范围，通常还会表现出骨盆前倾和腹部松弛无力。当骨盆过度向前倾时，影响到了下背部的生理弯曲，使这个人在看起来像是在“撅屁股”一样。腰椎过度前凸，尤其是幼儿和妇女，女性在怀孕之后容易出现这种问题，久坐人群也容易出现。",
              imageUrl: "https://syh-img.shareyourhealth.cn/6syh_assess_faq_yaozhuiqiantuguodu.png",
            }
          ]
        }, {
          title: "什么是腰椎前凸减少？",
          mediaList: [
            {
              text: "腰椎曲度减少，也是常说的腰椎的生理曲度变直，原因多是人们长期采取不良姿势，造成脊旁软组织过于紧张，肌肉力量及关节的稳定性下降，脊柱处于过伸或过屈状态，为了减轻压力，身体采取的代偿性体态改变，长此以往就导致生理曲度变形。此类人群容易产生腰椎类疾病，所以一定要尽早通过功能性锻炼恢复生理曲度。",
              imageUrl: "https://syh-img.shareyourhealth.cn/7syh_assess_faq_yaozhuiqiantujianshao.png",
            }
          ]
        }, {
          title: "什么是脊柱侧弯？",
          mediaList: [
            {
              text: "脊柱侧弯是指由于不良习惯引起的脊柱变形的情况，要注意你平时是否有背单肩包的习惯？ 你是否惯用右侧身体？你的站姿坐姿是否会明显倾斜？这些不对称的习惯会增加高低肩和脊柱侧弯的发生率。如果是左肩高右肩低，很可能是左侧斜方肌、斜角肌紧张，右侧腰方肌紧张。左右相反的话同理。体态的发生往往是牵一发而动全身的。脊柱侧弯往往伴随着高低肩，还有骨盆不正，长短腿，脚踝内外翻等。",
              imageUrl: "https://syh-img.shareyourhealth.cn/8syh_assess_faq_jizhucewan.png",
            }
          ]
        }
      ],
    }, {
      title: "臀胯",
      faqs: [
        {
          title: "什么是臀部扁平/下垂？",
          mediaList: [
            {
              text: "长期久坐不运动等不良习惯造成臀部扁平下榻，在日常穿搭中，臀部下榻扁平也会显得穿衣没有立体感，视觉上也会影响腰部线条的展现。臀肌一直无力，严重的会引起骨盆不稳，骨盆前倾，X/OX型腿，膝盖痛，膝超伸，假胯宽，扁平足，臀部下垂，妈妈臀等一系列问题。",
              imageUrl: "https://syh-img.shareyourhealth.cn/9syh_assess_faq_tunbuxiazhui.png",
            }
          ]
        }, {
          title: "什么是臀两侧凹陷？",
          mediaList: [
            {
              text: "臀部收紧的时候，臀部的左右两侧正常就是往里面凹陷的，这是正常臀部的形态。但是这个对女生来说往往觉得看起来臀部并不是那么圆润好看，这主要是因为臀部部分肌群偏弱，骨盆和骶髂关节失衡更容易有这种现象，通过一些特定训练可以改善臀凹陷问题。",
              imageUrl: "https://syh-img.shareyourhealth.cn/10syh_assess_faq_tunouxian.png",
            }
          ]
        }, {
          title: "什么是假胯宽？",
          mediaList: [
            {
              text: "假胯宽是困扰很多姐妹的体态问题之一，它会导致屁股大，腿粗，穿裤子不好看等，它的主要成因是由于髋关节内旋。很多不良习惯例如走路姿势内八、夹腿、跷二郎腿等，这会导致髋关节的过度内旋。假胯的人在走路时，双腿会呈现内旋，同时大腿外侧肌肉越来越发达，久而久之就形成了假胯。",
              imageUrl: "https://syh-img.shareyourhealth.cn/11syh_assess_faq_jiakuakuan.png",
            }
          ]
        }
      ],
    }, {
      title: "腿型",
      faqs: [
        {
          title: "什么是X/XO/O型腿？",
          mediaList: [
            {
              text: "X型腿:也就是我们常看到的两腿并拢后似字母“X”，在医学上称为膝外翻，是指在站立位时，双侧膝关节并拢而踝关节不能并拢。X型腿对膝关节影响最大，最早磨损的部位是髌骨，行走摩擦使髌骨收到不同程度的损害。运动跑步时，髌骨软骨的磨损也比正常腿型要严重一些，步入中年以后，容易出现膝关节内侧酸痛等膝盖不适。\n\n" +
                "XO腿型:表现为大腿部分到膝盖的位置能并拢，小腿外翻导致中间的空隙大，脚踝可以并并拢。这是由于臀部肌肉的无力，大腿和小腿之间平衡被打破，继而会导致大腿内旋，小腿外翻，股骨（大腿骨）和胫骨（小腿骨）发生了旋转，给人一种在外观上骨头长歪了的错觉。\n\n" +
                "O型腿：尝试把脚并拢，把手指放在膝盖中间，如果超过 2 指的宽度，就有轻微的 O 型腿。O型腿让膝关节内外侧受力不均匀，会增加膝关节内侧的压力，腿部高冲击力运动例如跑步， 您当前的O 形腿由于股骨外侧的旋转，很容易将压力集中于膝盖内侧，从而形成膝关节疼痛或者关节炎。",
              imageUrl: "https://syh-img.shareyourhealth.cn/12syh_assess_faq_tuixing.png",
            }
          ]
        }
      ],
    }, {
      title: "大腿小腿",
      faqs: [
        {
          title: "什么是大腿肌肉外翻？",
          mediaList: [
            {
              text: "大腿正面看起来感觉肌肉向外翻出去了，其实是当大腿前内侧和前外侧力量失衡，外侧的突出会更明显，特别是外侧靠上的位置，所以还会伴有假胯宽。根本原因还是臀中小肌等核心极润激活不足，那么这些大腿外侧的辅助肌肉就会反客为主变得越来越粗壮，看起来就像是肌肉往外翻了出去。",
              imageUrl: "https://syh-img.shareyourhealth.cn/13syh_assess_faq_datuiwaifan.png",
            }
          ]
        }, {
          title: "什么是大腿前侧突出？",
          mediaList: [
            {
              text: "大腿前侧突出，在站着的时候，大腿前面看着都像是凸起来了一块肉，很可能并不是因为脂肪太多，而是因为大腿前侧的股四头肌比较发达。久坐时，大腿后侧腘绳肌与臀部一直处于放松、伸长状态。",
              imageUrl: "https://syh-img.shareyourhealth.cn/14syh_assess_faq_datuiqiantu.png",
            }
          ]
        }, {
          title: "什么是小腿外翻/粗壮？",
          mediaList: [
            {
              text: "下肢力线不正导致胫骨外旋，带动小腿肌肉外翻，使小腿看起来弯曲、粗壮。可能由于日常步态不对，或者长期足底力量不够，导致我们小腿表层的肌肉过于紧张，而重心移向足弓内侧，就会慢慢让小腿变粗。",
              imageUrl: "https://syh-img.shareyourhealth.cn/15syh_assess_faq_xiaotuichu.png",
            }
          ]
        }
      ],
    }, {
      title: "骨盆",
      faqs: [
        {
          title: "什么是骨盆前倾/后倾/侧倾/前移？",
          mediaList: [
            {
              text: "骨盆前倾：侧面观髂前上棘处于耻骨联合前的骨盆形态。肚子上肉多一方面跟体脂高有关系，另一方面也跟你骨盆前倾的体态让小腹凸起，久坐容易让脂肪在腰腹堆积有关。\n\n" +
                "骨盆后倾：侧面观髂前上棘处于耻骨联合后方的骨盆形态。骨盆偏离了中立位置向后倾斜，肉眼看起来就是小肚子突出，弯腰驼背，臀部塌陷，甚至出现走路不稳，直观的感受就是，很颓废没气质，但更重要的问题是长久这种体态会给你的腰椎带来巨大压力甚至腰椎疼痛。\n\n" +
                "骨盆前移：骨盆位于身体中位线前方的骨盆形态。骨盆习惯性向前移，造成腰椎不正常的前凸出。主要还是由于不正常的站姿，坐姿导致身体前后肌力不平衡。腘绳肌与臀部肌肉的松弛，股四头肌的相对紧张，加之久坐人群的腹部松弛，很容易造成骨盆前移的体态问题，这也是导致腰疼的常见起因之一，如果忽略不改正，引起的身体问题会更多。",
              imageUrl: "https://syh-img.shareyourhealth.cn/16syh_assess_faq_gupeng1.png",
            }, {
              text: "骨盆侧倾：骨盆侧倾通俗的说就是骨盆歪了，体态上容易出现高低肩，骨盆侧倾，两脚足弓也不一样高。",
              imageUrl: "https://syh-img.shareyourhealth.cn/17syh_assess_faq_gupeng2.png",
            }
          ]
        }
      ],
    }, {
      title: "足底",
      faqs: [
        {
          title: "什么是足内翻？",
          mediaList: [
            {
              text: "跟骨内翻，足弓高于正常足，这种足弓下走路、运动时重心通常都会向脚掌外侧跑，就会产生踝关节外侧韧带损伤以及踝扭伤等问题。",
              imageUrl: "https://syh-img.shareyourhealth.cn/18syh_assess_faq_zuneifan.png",
            }
          ]
        }, {
          title: "什么是足外翻？",
          mediaList: [
            {
              text: "扁平足也就是内侧足弓塌陷。表现为足弓消失，足底扁平。而且扁平足从后面看，跟骨是外翻的，不是直的。站立的时候向内塌陷，走起路来还容易内八和X腿。扁平足不仅仅是脚部的问题，它会一直影响到腰部。因为，这种错位的状态会一直连接到腰部，造成盆骨前倾，膝关节内扣(X型腿)。骨头的位置不对，你的动作姿态就不对，动作姿态不对，肌肉发力不对。那么，脚底疼、小腿紧张、背疼都可能跟扁平足有关系。",
              imageUrl: "https://syh-img.shareyourhealth.cn/19syh_assess_faq_zuwaifan.png",
            }
          ]
        }
      ],
    }
  ];

  const renderTabContent = tab => (
    <Tabs.Tab title={tab.title} key={tab.title}>
      {(tab.faqs || []).map((faqItem, index) => (
          <AssessFaqItem key={`${tab.title}_${index}`} faq={faqItem}>
          </AssessFaqItem>
      ))}
    </Tabs.Tab>
  );

  return (
    <DocumentTitle title={"体态问题指南"}>
      <div style={{paddingTop: '10px'}}>
        <SafeArea position='top' />
        <Tabs>
          {tabList.map(e => renderTabContent(e))}
        </Tabs>
        <SafeArea position='bottom' />
      </div>
    </DocumentTitle>
  );
};

export default AssessFAQPage;
