import React, { useState, useEffect } from "react";
import { Toast, Result, ImageViewer } from "antd-mobile";
import { QuestionCircleFill, InformationCircleFill } from "antd-mobile-icons";
import { useHistory } from 'react-router-dom';

import * as assessApi from "../../apis/assessApi";
import dsBridge from "dsbridge";

import IMG_SCO from "./images/ic_assess_sco.png";
import "./index.less";

const FootResultPage = (props) => {
  const [assess, setAssess] = useState({});
  const [loadStatus, setLoadStatus] = useState("NONE");
  const [bodyList, setBodyList] = useState([]);

  const fetchData = async (data) => {
    const assessId = props.match.params.assessId;

    setLoadStatus("LOADING");
    var isSuccess = true;

    var res = await assessApi.getAssess(assessId, data);
    if (res.errCode !== 0) {
      Toast.show({
        content: res.errMsg,
        icon: "fail",
      });
      isSuccess = false;
    } else {
      setAssess(res);
      setLoadStatus(isSuccess ? "LOADED" : "FAILED");

      let resList = [];
      let lastBodyList = [];
      let lastBody = "";
      (res.bodyList || []).forEach((it) => {
        if (lastBody !== it.body) {
          lastBody = it.body;
          if (lastBodyList.length > 0) {
            resList.push(lastBodyList);
          }
          lastBodyList = [];
        }
        lastBodyList.push(it);
      });
      if (lastBodyList.length > 0) {
        resList.push(lastBodyList);
      }
      setBodyList(resList);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    if (query.get("vc") !== null) {
      const headers = {
        "X-Mp-LoginToken": query.get("vc"),
        "X-DeviceToken": query.get("vd"),
        "X-Mp-AppId": "wxd3025ff909e390bb",
        "X-Platform": "iOS",
        "X-App-Id": "HEALTH",
        "X-Version": "4.6.0",
        "X-Build": "20230401",
        "X-Channel": "AppStore",
      };
      fetchData(headers);
      return;
    }

    const params = {
      action: "getToken",
    };

    dsBridge.call(
      "egghealth.asyncHandler",
      JSON.stringify(params),
      async (data) => {
        if (data) {
          fetchData(JSON.parse(data) || {});
        } else {
          setLoadStatus("FAILED");
        }
      }
    );
  }, [props.match.params.assessId]);

  const onRetry = () => {
    const query = new URLSearchParams(props.location.search);
    if (query.get("vc") !== null) {
      const headers = {
        "X-Mp-LoginToken": query.get("vc"),
        "X-DeviceToken": query.get("vd"),
        "X-Mp-AppId": "wxd3025ff909e390bb",
        "X-Platform": "iOS",
        "X-App-Id": "HEALTH",
        "X-Version": "4.6.0",
        "X-Build": "20230401",
        "X-Channel": "AppStore",
      };
      fetchData(headers);
      return;
    }

    const params = {
      action: "getToken",
    };
    dsBridge.call(
      "egghealth.asyncHandler",
      JSON.stringify(params),
      async (data) => {
        if (data) {
          fetchData(JSON.parse(data) || {});
        } else {
          setLoadStatus("FAILED");
        }
      }
    );
  };

  const onShowImage = () => {
    const imgs = assessData.markedXRayImages || [];

    ImageViewer.Multi.show({
      images: imgs.map((e) => e.urlPrefix + "/" + e.qiniuKey),
      defaultIndex: 0,
    });
  };

  let history = useHistory();
  const handleClick = (url) => {
    history.push(url);
  }

  const assessData = JSON.parse(assess.assessData || "{}");
  const gradeColors = [
    '#657181',
    '#21DCC6',
    '#21DCC6',
    '#FF8832',
    '#FF8832',
    '#ff4545'
  ]

  const showWarning = loadStatus === "FAILED";
  let coverImg = "";
  if (assessData.markedXRayImages && assessData.markedXRayImages.length > 0) {
    let fImg = assessData.markedXRayImages[0];
    coverImg = fImg.urlPrefix + "/" + fImg.qiniuKey;
  }

  return (
    <div className="ar-page">
      <div style={{ display: showWarning ? "display" : "none" }}>
        <Result
          status="warning"
          title="数据加载失败"
          description={
            <div className="assess-result-message">请刷新页面重试</div>
          }
        />
        <div className="assess-result-button" onClick={onRetry}>
          刷新重试
        </div>
      </div>
      {/* <div className="assess-result-header">
        <div className="assess-result-header-title">{assess.title}</div>
        <div className="assess-result-header-id">{`NO.${assess.id}`}</div>
        <div className="assess-result-header-info">
          {`${userData.gender === "0" ? "男" : "女"} | 
            ${userData.age}岁 | ${userData.height}cm | ${userData.weight}kg`}
        </div>
      </div> */}

      <div className="ar-sco">
        <div className="ar-sco-left" onClick={onShowImage}>
          <img src={coverImg} alt=""></img>
          <label className="ar-sco-left-label">{`共${(assessData.markedXRayImages || []).length}张`}</label>
        </div>
        <div className="ar-sco-mid">
          <div>脊柱侧弯分型</div>
          <div className="ar-sco-mid-info">
            <div className="ar-sco-mid-info-type">
              {assessData.scoliosisType}
            </div>
            <div className="ar-sco-mid-info-tips" onClick={() => handleClick('/health/scoliosis/types')}>
              <QuestionCircleFill fontSize={16} style={{ marginRight: 2 }} />
              什么是分型?
            </div>
          </div>
        </div>
        <div className="ar-sco-right">
          <img src={IMG_SCO} alt="" />
        </div>
      </div>

      <div className="assess-result-section-title">视频报告</div>
      {assessData.postureVideo && (
        <div className="ar-posture">
          <video
            style={{ width: "100%", height: "100%" }}
            controls
            playsInline
            x5-playsinline="true"
            x5-video-player-fullscreen="true"
            webkit-playsinline="true"
            src={
              assessData.postureVideo.urlPrefix +
              "/" +
              assessData.postureVideo.assetKey
            }
            poster={assessData.postureVideo.coverUrl}
          ></video>
        </div>
      )}

      <div className="ar-sco-title">
        评估明细
        <div className="ar-sco-title-result" onClick={() => handleClick('/health/scoliosis/assessintro')}>
          <InformationCircleFill font-size="14px" style={{ marginRight: '3px'}}/>
          评估选项说明
        </div>
      </div>
      <div className="ar-bodylist">
        {bodyList.map((items) => (
          <div className="ar-bodylist-card" key={`card_${items[0].id}`}>
            <div className="ar-bodylist-card-body">{items[0].body}</div>
            <div className="ar-bodylist-card-items">
              {items.map((body) => (
                <div className="ar-bodylist-card-item" key={body.id}>
                  <div className="ar-bodylist-card-item-name">{body.name}</div>
                  <div className="ar-bodylist-card-item-value" style={{ color: gradeColors[body.gradeLevel] }}>
                    {body.gradeLevelName}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {assess.equipments && assess.equipments.length > 0 && (
        <div>
          <div className="assess-result-section-title">准备训练器材</div>
          <div className="ar-eqp">
            {assess.equipments.map((item) => (
              <div className="ar-eqp-item" key={item.id}>
                <img src={item.coverUrl} alt=""></img>
                <div className="ar-eqp-name">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div style={{ margin: "0 14px" }}>
        <div className="assess-result-placehlder"></div>
        {(assess.introList || []).map((intro, iIndex) => (
          <div
            key={`intro_${iIndex}`}
            className="assess-result-article-wrapper"
          >
            <div className="assess-result-article-title">{intro.title}</div>
            {(intro.itemList || []).map((introItem, itemIndex) => (
              <div
                key={`introItem_${iIndex}_${itemIndex}`}
                className="assess-result-article-item"
              >
                <div
                  className="assess-result-article-text"
                  dangerouslySetInnerHTML={{
                    __html: (introItem.text || "").replace(/\n/g, "<br />"),
                  }}
                ></div>
                {(introItem.imageList || []).map((mediaItem, mIndex) => (
                  <div
                    key={`media_${iIndex}_${itemIndex}_${mIndex}`}
                    className="assess-result-article-media"
                  >
                    {mediaItem.videoKey ? (
                      <video
                        src={mediaItem.videoUrl}
                        controls
                        controlsList={"nodownload nofullscreen"}
                        disablePictureInPicture
                        playsInline
                        x5-playsinline
                        webkit-playsinline="true"
                        poster={mediaItem.imageUrl + "/w1024.75.1.jpg"}
                      ></video>
                    ) : (
                      <img
                        src={mediaItem.imageUrl + "/w1024.75.1.jpg"}
                        alt=""
                      ></img>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FootResultPage;
