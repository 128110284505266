import React, { forwardRef, useState } from 'react';
import { Picker } from 'antd-mobile'

import '../textInput/index.less';

const PickerComp = forwardRef((props, _ref) => {
  const [visible, setVisible] = useState(false)

  const handlePickerChange = (val) => {
    if (val && val.length > 0) {
      props.onChange(val[0]);
    }
  }

  let curTitle = "";
  (props.data || []).forEach((d) => {
    if (d.value === props.value) {
      curTitle = d.label;
    }
  })

  return (
    <>
      <label className="text-label" onClick={() => {
        setVisible(true)
      }}>
        <div className="text-info" needsclick="true">
          <div className="text-title">{props.title}</div>
          {!(props.value === undefined || props.value === '') ?
            <div className="text-value">{curTitle}</div> :
            <div className="text-placeholder">{props.placeholder}</div>
          }
        </div>
      </label>
      <Picker
        columns={[props.data]}
        onConfirm={handlePickerChange}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
      >
      </Picker>
    </>
  );
});

export default PickerComp;
