import React, {forwardRef, useState} from 'react';
import { DatePicker } from 'antd-mobile'

import '../textInput/index.less';

const DatePickerComp = forwardRef((props, _ref) => {
  const [visible, setVisible] = useState(false)

  const handlePickerChange = (val) => {
    const pad = n => n < 10 ? `0${n}` : n;

    if (val !== null && val !== undefined) {
      const dateStr = `${val.getFullYear()}-${pad(val.getMonth() + 1)}-${pad(val.getDate())}`;
      props.onChange(dateStr);
    } else {
      props.onChange("");
    }
  }

  return (
    <>
      <label className="text-label" onClick={() => {
        setVisible(true)
      }}>
        <div className="text-info" needsclick="true">
          <div className="text-title">{props.title}</div>
          {!(props.value === undefined || props.value === null) ?
            <div className="text-value">{props.value}</div> :
            <div className="text-placeholder">{props.placeholder}</div>
          }
        </div>
      </label>
      <DatePicker
        precision="day"
        title="选择日期"
        extra="请选择"
        visible={visible}
        onSelect={handlePickerChange}
        onClose={() => {
          setVisible(false)
        }}
      >
      </DatePicker>
    </>
  );
});

export default DatePickerComp;
