import React, { forwardRef, useState, useEffect } from "react";

import "./index.less";

const ListSelector = forwardRef((props, _ref) => {
  const [curValue, setCurValue] = useState(props.value)
  useEffect(()=>{
    setCurValue(props.value)
  },[props.value])

  const handleItemClicked = (val) => {
    setCurValue(val);
    props.onChange(val);
  };

  return (
    <>
      <div className="list-selector">
        <div className="list-selector-title">{props.title}</div>
        <div className="list-selector-value">
          {(props.data || []).map((item) => (
            <div
              className={[
                "list-selector-value-item",
                item.value === curValue ? "list-selector-value-item-selected" : ""
              ].join(" ")}
              onClick={() => handleItemClicked(item.value)}
              key={item.value}
              needsclick="true"
            >
              <div>{item.label}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

export default ListSelector;
