import React from "react";
import DocumentTitle from "react-document-title";

import "./index.less";

const ScoliosisAssessIntroPage = () => {
  const typeList = [{
    title: '① 什么事侧弯？',
    image: 'https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_assess_1.png',
    content: '人体从背后向前的方向看，脊柱发生左右的弯曲，即为冠状面的侧弯。'
  }, {
    title: '② 什么是曲度？',
    image: 'https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_assess_2.png',
    content: '人体从侧面看，生理曲度发生过度的弯曲或变直，即为矢状面的侧弯。正常的生理曲度，是颈椎腰椎向前弧形突出。'
  }, {
    title: '③ 什么是旋转？',
    image: 'https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_assess_3.png',
    content: '以脊柱作为轴心，脊柱发生类似拧毛巾似的顺时针或逆时针旋转，即为水平面的旋转。'
  }];

  return (
    <DocumentTitle title={"脊柱侧弯评估项说明"}>
      <div className="stp">
        <div class="stp-header-main">
          <div class="stp-header-main-subTitle">脊柱侧弯评估项说明</div>
          <div class="stp-header-main-desc">
            <div class="stp-header-main-desc-text">什么事侧弯、曲度、旋转？</div>
          </div>
        </div>

        <div className="stp-types">
          {typeList.map((item) => (
            <div className="stp-types-item">
              <div className="stp-types-item-title">
                <span>{item.title}</span>
              </div>
              <div className="stp-types-item-img">
                <img src={item.image} alt=""></img>
                <div className="stp-types-item-img-desc">{item.content}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default ScoliosisAssessIntroPage;
