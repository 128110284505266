import React from 'react';

import './index.less';

const AssessFaqItem = props => {
  let faqInfo = props.faq;

  const mediaList = (faqInfo.mediaList || []).map((media, index) => (
    <div className="faq-item-media" key={`${media.title}_${index}`}>
      {(media.text && media.text.length > 0) ?
        <div
          className={"faq-item-text"}
          dangerouslySetInnerHTML={{ __html: (media.text || "").replace(/\n/g, "<br />") }}
        /> : null
      }
      {(media.imageUrl && media.imageUrl.length > 0) ?
        <div className={"faq-item-img"}>
          <img
            src={media.imageUrl}
            alt=""
            style={{ width: media.imageWidth ? media.imageWidth : "100%"}}
          />
        </div> : null
      }
      {(media.url && media.url.length > 0) ?
        <div className="faq-item-text"><a href={media.url} >{media.urlText}</a></div> : null
      }
    </div>
  ));

  return (
    <div className="faq-item-container">
      <div className="faq-item-title">
        {faqInfo.title}
      </div>
      {mediaList}
    </div>
  );
};

export default AssessFaqItem;