/**
 * Created by jimmy on 2019/11/7.
 */
export const getSearchByKey = (key, search = window.location.search) => {
	if(!search) return '';
	
	const _search = search.slice(1);
	const _searchArr = _search.split('&');
	const _map = {};
	_searchArr.map(item => {
		const _item = item.split('=');
		if(_item[0] && _item[1]) {
			_map[_item[0]] = _item[1];
    }
    return item;
	});
	return _map[key] || '';
};

export const setTitle = title => {
	if(document) {
		document.title = title;
	}
};

// loading
export const loading = {
	show: () => {
		document.getElementById('syh-loading').style.display = 'block';
	},
	hide: () => {
		document.getElementById('syh-loading').style.display = 'none';
	}
};

// toast
export const toast = {
	show: (txt) => {
		const $toast = document.getElementById('syh-toast');
		$toast.style.display = 'block';
		$toast.innerText = txt;
	},
	hide: () => {
		const $toast = document.getElementById('syh-toast');
		$toast.style.display = 'none';
		$toast.innerText = '';
	}
};

export const getUA = () => {
	if(!window || !window.navigator || !window.navigator.userAgent){
		return null;
	}
	
	const ua = window.navigator.userAgent.toLocaleLowerCase();
	
	return {
		ua,
		isInWechat: ua.indexOf('micromessenger') > -1,
		isInRongMeiApp: ua.indexOf('rongmeiapp/qingpu') > -1 || ua.indexOf('rongmeiapp') > -1,    // mainly for QingPu
    isIOS: ua.indexOf("iphone") > -1 || ua.indexOf("ios") > -1,
    isAndroid: ua.indexOf("android") > -1 || ua.indexOf("linux") > -1
	}
};

export const _alert = (txt) => {
	if(!txt) return;

	toast.show(txt);

	setTimeout(() => {
		toast.hide();
	}, 1500);
};