import * as Request from '../common/request';

export const getRegisterPage = async (code) => {
  let settings = {
    url: "/api/v1/user/invite/register/page/" + code,
    data: {},
    hideError: true,
  };

  return await Request.get(settings);
};

export const getInvitePage = async (headers) => {
  let settings = {
    url: "/api/v1/user/invite/page",
    data: {},
    hideError: true,
    headers: headers
  };

  return await Request.get(settings);
};

export const inviteMobile = async (params) => {
  let settings = {
    url: "/api/v1/user/invite/submit",
    data: params,
    hideError: true,
  };

  return await Request.post(settings);
};

export const getStat = async (params) => {
  let settings = {
    url: "/api/v1/user/invite/stat",
    data: params,
    hideError: true,
  };

  return await Request.get(settings);
};
