import React from 'react';
import DocumentTitle from 'react-document-title';

import './index.less';

import HealthStageIntroItem from './introItem';
import IMG_HEADER from './images/ic_mass_header.png';
import IMG_FOOTER from './images/ic_mass_footer.png';

const HealthStagePage = () => {

  const stageList = [{
    stage: 0,
    title: "运动评估·动作模式/受伤风险",
    desc: "通过观察体态问题，生活中常见的动作模式问题，" +
      "评估你的运动中可能的受伤风险点，当前损伤产生的原因，以及相对应的解决方案。",
    videoUrl: "https://syh-video.shareyourhealth.cn/lo-dOj45Pp0BX36AoP-R376MNz7F_thumb.mp4",
    coverUrl: "https://syh-img.shareyourhealth.cn/lo-dOj45Pp0BX36AoP-R376MNz7F_cover.jpg",
  }, {
    stage: 1,
    title: "小学阶段·找发力感",
    desc: "小学阶段重点是学习肌肉发力感，大多数健身小白谈及运动时，在关注什么？" +
      "男生讨论深蹲只是问，能蹲多重，很少考虑持续的发力感与身体后链、脚踝；" +
      "女生讨论减脂，就是开合跳做了多少下，很少考虑膝盖是否内扣；" +
      "谈论跑步只看跑量与时间，不问跑姿与发力感。\n" +
      "然而，动作的数量与重量并不能决定动作的好坏，动作质量远比数量更重要。\n" +
      "动作质量就是最基础的肌肉发力感与姿势问题。",
    videoUrl: "https://syh-video.shareyourhealth.cn/ls2dMvUi7PInKCpYzFpyrd_BuwIt_thumb.mp4",
    coverUrl: "https://syh-img.shareyourhealth.cn/ls2dMvUi7PInKCpYzFpyrd_BuwIt_cover.jpg",
  }, {
    stage: 2,
    title: "中学阶段·掌握正确姿势",
    desc: "肌肉跨过的关节需要两个或两个以上。动作关节越多、涉及的肌肉越多，对神经肌肉控制能力的要求也就越高。",
    videoUrl: "https://syh-video.shareyourhealth.cn/lh40h3szryu4QFc4bH-FUuqaQSBQ_thumb.mp4",
    coverUrl: "https://syh-img.shareyourhealth.cn/lh40h3szryu4QFc4bH-FUuqaQSBQ_cover.jpg",
  }, {
    stage: 3,
    title: "大学阶段·运动表现",
    desc: "大学阶段的学习重点是，练习单腿支撑的动作。训练体位特点是单腿支撑的站立动作，" +
      "弓箭步、单腿硬拉和走路姿势等。目的是尽快回到日常生活与运动中，" +
      "而单腿模式，是日常生活中最常见到的场景，例如上下楼梯、走路等。",
    videoUrl: "https://syh-video.shareyourhealth.cn/lgIIwOYaScKqpA4eigHcDc9MydbS_thumb.mp4",
    coverUrl: "https://syh-img.shareyourhealth.cn/lgIIwOYaScKqpA4eigHcDc9MydbS_cover.jpg",
  }];

  return (
    <DocumentTitle title={"MASS动作质量体系"}>
    <div className="bg-container">
      <img className="stage-header" src={IMG_HEADER} alt=""/>
      {stageList.map(stageInfo => {
        return (
          <div className="stage-item-wrapper" key={`stage_${stageInfo.stage}`}>
            <HealthStageIntroItem stage={stageInfo}/>
          </div>
        );
      })}
      <img className="stage-footer" src={IMG_FOOTER} alt=""/>
    </div>
    </DocumentTitle>
  );
};

export default HealthStagePage;