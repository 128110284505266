import React from 'react';
// import createDOMForm from 'rc-form/lib/createDOMForm';

import './index.less';
// import DatePickerComp from '../../components/picker/datePicker';

const PageHome = props => {
  // const { getFieldError, getFieldDecorator, getFieldProps } = props.form;

  return (
    <div>蛋壳跟练&蛋壳健康欢迎您</div>
    // <div>
    //       <DatePickerComp title="年龄" placeholder="请选择"
    //         {...getFieldProps('age', {
    //           initialValue: null,
    //           rules: [{
    //             type: 'string',
    //             required: true,
    //             message: '请选择年龄',
    //           }],
    //         })}
    //       >
    //       </DatePickerComp>
    // </div>
  );
}

// export default createDOMForm()(PageHome);
export default PageHome;