import React from 'react';

import './index.less';
import IMG_MASS from './images/ic_mass_logo.png';
import IMG_STAGE_ASSESS from './images/ic_health_movement_assess.png';
import IMG_STAGE_ONE from './images/ic_health_movement_one.png';
import IMG_STAGE_TWO from './images/ic_health_movement_two.png';
import IMG_STAGE_THREE from './images/ic_health_movement_three.png';

const HealthStageIntroItem = props => {
  let stageInfo = props.stage;

  const stageImageList = [IMG_STAGE_ASSESS, IMG_STAGE_ONE, IMG_STAGE_TWO, IMG_STAGE_THREE];

  return (
    <div className="stage-container">
      <div className="stage-title-container">
        <img className="mass-icon" src={IMG_MASS} alt=""/>
        <div className="stage-title">{stageInfo.title}</div>
      </div>
      <div className="stage-video">
        <video
          controls
          controlsList={"nodownload nofullscreen"}
          disablePictureInPicture
          playsInline
          x5-playsinline
          webkit-playsinline="true"
          src={stageInfo.videoUrl}
          poster={stageInfo.coverUrl}>
        </video>
      </div>
      <div className="stage-info">
        <img className="mass-icon" src={IMG_MASS} alt=""/>
        <div className="mass-title">MASS动作体系</div>
        <img className="stage-icon" src={stageImageList[stageInfo.stage]} alt=""/>
      </div>
      <div className="stage-desc">{stageInfo.desc}</div>
    </div>
  );
};

export default HealthStageIntroItem;