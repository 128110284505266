import React, {useEffect, useState} from 'react';
import { useParams, useLocation } from "react-router-dom";
import createDOMForm from 'rc-form/lib/createDOMForm';
import {TextArea, Toast, Result, Input, Space } from 'antd-mobile'

import * as qiniuApi from '../../apis/qiniuApi';
import * as assessApi from '../../apis/assessApi';
import dsBridge from 'dsbridge';

import './index.less';
import IMG_FRONT_DEMO from './images/assess_front_demo.jpg';
import IMG_BACK_DEMO from './images/assess_back_demo.jpg';
import IMG_SIDE_DEMO from './images/assess_side_demo.jpg';
import IMG_INFO from './images/ic_assess_info.png';
import IMG_WAITING from './images/ic_assess_waiting.svg';
import IMG_WARNING from './images/ic_assess_warning.svg';

import UploadComp from '../../components/upload';
import TextInputComp from '../../components/textInput';
import PickerComp from '../../components/picker';

const FootAssessPage = props => {
  const [headers, setHeaders] = useState({});
  const [assess, setAssess] = useState({});
  const [assessData, setAssessData] = useState({});
  const [loadStatus, setLoadStatus] = useState("NONE");
  let { assessId } = useParams();
  const location = useLocation();

  const getTokenAndFetchData = async () => {
    const query = new URLSearchParams(location.search);
    if (query.get("vc") !== null && !((headers && headers["X-Mp-LoginToken"]))) {
      const tokenInfo = {
        "X-Mp-LoginToken": query.get("vc"),
        "X-DeviceToken": query.get("vd"),
        "X-Mp-AppId": "wxd3025ff909e390bb",
        "X-Platform": "iOS",
        "X-App-Id": "HEALTH",
        "X-Version": "4.6.0",
        "X-Build": "20230401",
        "X-Channel": "AppStore"
      }

      setHeaders(tokenInfo);
      await fetchData(tokenInfo);
    } else if (headers && headers["X-Mp-LoginToken"]) {
      await fetchData(headers);
    } else {
      const params = {
        action: "getToken"
      };

      dsBridge.call("egghealth.asyncHandler", JSON.stringify(params), async (data) => {
        if (data) {
          const tokenInfo = JSON.parse(data) || {};
          setHeaders(tokenInfo);
          await fetchData(tokenInfo);
        } else {
          setLoadStatus("FAILED");
        }
      });
    }
  }

  const fetchData = async (tokenInfo) => {
    setLoadStatus("LOADING");
    var isSuccess = true;

    var res = await assessApi.getAssess(assessId, tokenInfo)
    if (res.errCode !== 0) {
      Toast.show({
        content: res.errMsg,
        icon: 'fail',
      });
      isSuccess = false;
    } else {
      let tmpValues = res.submitData ? JSON.parse(res.submitData) : {};
      setAssessData(tmpValues);
      setAssess(res);
    }

    setLoadStatus(isSuccess ? "LOADED" : "FAILED");
  };

  useEffect(() => {
    // const assessId = props.match.params.assessId;
    getTokenAndFetchData();
    // return () => {
    //   if (assess.status !== "SUBMITTED") {
    //     let values = props.form.getFieldsValue([]);
    //     localStorage.setItem(`ASSESS_DATA_${assessId}`, JSON.stringify(values));
    //   }
    // }
  }, [assessId])

  const onSubmit = (e) => {
    props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        var tmpValues = {
          ...values,
          hasWeiDuInfo: true
        }

        getTokenAndSubmitAssess({
          id: assessId,
          name: tmpValues.name,
          coverKey: (tmpValues.userFrontImage || {}).qiniuKey,
          submitData: JSON.stringify(tmpValues),
        });
      } else {
        Toast.show({
          content: '资料不完整，请填写完整后提交～',
          icon: 'fail',
        });
      }
    });
  }

  const onSaveDraft = (e) => {
    e.preventDefault();
    var values = props.form.getFieldsValue();
    values = {
      ...values,
      hasWeiDuInfo: true
    }
    saveDraft({
      id: assessId,
      name: values.name,
      coverKey: (values.userFrontImage || {}).qiniuKey,
      submitData: JSON.stringify(values),
    }, headers);
  }

  const getTokenAndSubmitAssess = async (data) => {
    if (headers && headers["X-Mp-LoginToken"]) {
      console.log("hasToken");
      submitAssess(data, headers);
    } else {
      console.log("noToken");

      const params = {
        action: "getToken"
      };

      dsBridge.call("egghealth.asyncHandler", JSON.stringify(params), async (tokenStr) => {
        if (tokenStr) {
          var tokenInfo = JSON.parse(tokenStr) || {};
          setHeaders(tokenInfo);
          submitAssess(data, tokenInfo);
        } else {
          Toast.show({
            content: '获取登录信息失败',
            icon: 'fail',
          });
        }
      });
    }
  }

  const submitAssess = async (data, tokenInfo) => {
    var res = await assessApi.submitAssess(data, tokenInfo);
    if (res.errCode !== 0) {
      Toast.show({
        content: res.errMsg,
        icon: 'fail',
      });
      if (res.errCode === 4032) {
        setHeaders({});
      }
    } else {
      Toast.show({
        content: '资料提交成功，请耐心等待评估结果',
        icon: 'success',
      });
      assess.status = "SUBMITTED";
      setAssess({
        ...assess,
        status: "SUBMITTED",
      });
    }
  }

  const saveDraft = async (data, tokenInfo) => {
    var res = await assessApi.saveAssess(data, tokenInfo);
    if (res.errCode !== 0) {
      Toast.show({
        content: res.errMsg,
        icon: 'fail',
      });
      if (res.errCode === 4032) {
        setHeaders({});
      }
    } else {
      Toast.show({
        content: '资料保存成功',
        icon: 'success',
      });
    }
  }

  const getPicToken = async (callback) => {
    var res = await qiniuApi.getPicToken(headers);
    if (res.errCode !== 0) {
      Toast.show({
        content: '获取图片上传码失败',
        icon: 'fail',
      });
      callback({ token: '' });
    } else {
      callback(res);
    }
  }

  const getVideoToken = async (callback) => {
    var res = await qiniuApi.getVideoToken(headers);
    if (res.errCode !== 0) {
      Toast.show({
        content: '获取视频上传码失败',
        icon: 'fail',
      });
      callback({ token: '' });
    } else {
      callback(res);
    }
  }

  const onRetry = () => {
    fetchData(headers);
  }

  const checkImageSize = (file) => {
    if (file.size / 1024 / 1024 > 20) {
      Toast.show({
        content: '图片太大，图片大小不能超过20MB',
        icon: 'fail',
      });
      return false;
    }
    return true;
  }

  const checkVideoSize = (file) => {
    if (file.size / 1024 / 1024 > 200) {
      Toast.show({
        content: '视频太大，视频大小不能超过200MB',
        icon: 'fail',
      });
      return false;
    }
    return true;
  }

  var testList = [];
  var uploadVideoList = [];
  const imgHost = "https://syh-img.shareyourhealth.cn/";
  const vidHost = "https://syh-video.shareyourhealth.cn/";

  const allTestList = {
    "danTuiTunQiaoTest": {
      key: "danTuiTunQiaoTest",
      demoUrl: vidHost + "lgU9_V8FTj6euE10b2kX8TleJ_mA_thumb.mp4",
      poster: imgHost + "lgU9_V8FTj6euE10b2kX8TleJ_mA_cover.jpg",
      title: "单腿臀桥测试（秒）",
      inputList: [{
        type: 'number',
        title: "左腿支撑时间",
        placeholder: "请输入",
        name: "danTuiTunQiaoTestLeft",
      }, {
        type: 'number',
        title: "右腿支撑时间",
        placeholder: "请输入",
        name: "danTuiTunQiaoTestRight",
      }],
    },
    "tuiWeiDuTest": {
      key: "tuiWeiDuTest",
      demoUrl: vidHost + "luRNw5IPjQOXIx08QToDjCUo3D7Q_thumb.mp4",
      poster: imgHost + "luRNw5IPjQOXIx08QToDjCUo3D7Q_cover.jpg",
      title: "围度测试（厘米）",
      hidePain: true,
      inputList: [{
        title: "5cm",
        inputItems: [{
          type: 'number',
          title: "",
          placeholder: "请输入左腿5cm处围度",
          name: "tuiWeiDuTestLeft5",
        }, {
          type: 'number',
          title: "",
          placeholder: "请输入右腿5cm处围度",
          name: "tuiWeiDuTestRight5",
        }],
      }, {
        title: "10cm",
        inputItems: [{
          type: 'number',
          title: "",
          placeholder: "请输入左腿10cm处围度",
          name: "tuiWeiDuTestLeft10",
        }, {
          type: 'number',
          title: "",
          placeholder: "请输入右腿10cm处围度",
          name: "tuiWeiDuTestRight10",
        }]
      }, {
        title: "-10cm",
        inputItems: [{
          type: 'number',
          title: "",
          placeholder: "请输入左腿负10cm处围度",
          name: "tuiWeiDuTestLeftMinus10",
        }, {
          type: 'number',
          title: "",
          placeholder: "请输入右腿负10cm处围度",
          name: "tuiWeiDuTestRightMinus10",
        }]
      }],
    },
    "jiaoHuaiBeiQuTest": {
      key: "jiaoHuaiBeiQuTest",
      demoUrl: vidHost + "lisn4aZFdB9ZF7CIDYZaKp9JDDtX_thumb.mp4",
      poster: imgHost + "lisn4aZFdB9ZF7CIDYZaKp9JDDtX_cover.jpg",
      title: "脚踝背屈测试（厘米）",
      inputList: [{
        type: 'number',
        title: "左脚数据",
        placeholder: "请输入",
        name: "jiaoHuaiBeiQuTestLeft",
      }, {
        type: 'number',
        title: "右脚数据",
        placeholder: "请输入",
        name: "jiaoHuaiBeiQuTestRight",
      }],
    },
    "qianShenJuLiTest": {
      key: "qianShenJuLiTest",
      demoUrl: vidHost + "lulL7j05cfY5L89IQlDS8hDpYoAi_thumb.mp4",
      poster: imgHost + "lulL7j05cfY5L89IQlDS8hDpYoAi_cover.jpg",
      title: "前伸距离测试（厘米）",
      inputList: [{
        type: 'number',
        title: "左脚距离",
        placeholder: "请输入",
        name: "qianShenJuLiTestLeft",
      }, {
        type: 'number',
        title: "右脚距离",
        placeholder: "请输入",
        name: "qianShenJuLiTestRight",
      }],
    },
    "ceQiaoTest": {
      key: "ceQiaoTest",
      demoUrl: vidHost + "luLG_S02ubp2LPdC5tJbP4CUXyGa_thumb.mp4",
      poster: imgHost + "luLG_S02ubp2LPdC5tJbP4CUXyGa_cover.jpg",
      title: "侧桥测试（秒）",
      inputList: [{
        type: 'number',
        title: "左侧支撑时间",
        placeholder: "请输入",
        name: "ceQiaoTestLeft",
      }, {
        type: 'number',
        title: "右侧支撑时间",
        placeholder: "请输入",
        name: "ceQiaoTestRight",
      }],
    },
    "tunQiaoTest": {
      key: "tunQiaoTest",
      demoUrl: vidHost + "lgU9_V8FTj6euE10b2kX8TleJ_mA_thumb.mp4",
      poster: imgHost + "lgU9_V8FTj6euE10b2kX8TleJ_mA_cover.jpg",
      title: "单腿静止臀桥测试（秒）",
      inputList: [{
        type: 'number',
        title: "左腿支撑时间",
        placeholder: "请输入",
        name: "tunQiaoTestLeft",
      }, {
        type: 'number',
        title: "右腿支撑时间",
        placeholder: "请输入",
        name: "tunQiaoTestRight",
      }],
    },
    "fuWoChengTest": {
      key: "fuWoChengTest",
      demoUrl: vidHost + "ls4OvsOJ0X3eVu_7cj9gzRw4sMzP_thumb.mp4",
      poster: imgHost + "ls4OvsOJ0X3eVu_7cj9gzRw4sMzP_cover.jpg",
      title: "俯卧撑（数量）",
      inputList: [{
        type: 'text',
        title: "1分钟内数量",
        placeholder: "请输入数量",
        name: "fuWoChengTestCount",
      }],
    },
    "kouJianJuLiTest": {
      key: "kouJianJuLiTest",
      demoUrl: vidHost + "loAzJiVeyPJUAVQY-MuMXep7CYU0_thumb.mp4",
      poster: imgHost + "loAzJiVeyPJUAVQY-MuMXep7CYU0_cover.jpg",
      title: "扣肩距离（厘米）",
      inputList: [{
        type: 'text',
        title: "手掌长度",
        placeholder: "请输入手掌长度",
        name: "kouJianJuLiTestHand",
      }, {
        type: 'text',
        title: "左侧扣肩距离",
        placeholder: "请输入左侧距离",
        name: "kouJianJuLiTestLeft",
      }, {
        type: 'text',
        title: "右侧扣肩距离",
        placeholder: "请输入右侧距离",
        name: "kouJianJuLiTestRight",
      }],
    },
    "danTuiZhiChengTest": {
      key: "danTuiZhiChengTest",
      demoUrl: vidHost + "lvBWQ1zxMMNaJotiaiUTPp1F8xFR_thumb.mp4",
      poster: imgHost + "lvBWQ1zxMMNaJotiaiUTPp1F8xFR_cover.jpg",
      title: "单腿支撑时间测试（厘米）",
      inputList: [{
        type: 'number',
        title: "左腿支撑时间",
        placeholder: "请输入",
        name: "danTuiZhiChengTestLeft",
      }, {
        type: 'number',
        title: "右腿支撑时间",
        placeholder: "请输入",
        name: "danTuiZhiChengTestRight",
      }],
    }
  };

  const allVideoList = {
    "zuoZiXiongZhuiXuanZhuan": {
      key: "zuoZiXiongZhuiXuanZhuan",
      title: "坐姿胸椎旋转测试",
      demoUrl: vidHost + "lguW6qhXdv9xBzXYsOueBbx33Mfm_thumb.mp4",
      poster: imgHost + "lguW6qhXdv9xBzXYsOueBbx33Mfm_cover.jpg",
      name: "zuoZiXiongZhuiXuanZhuan",
    },
    "danTuiYinLa": {
      key: "danTuiYinLa",
      title: "单腿硬拉测试",
      demoUrl: vidHost + "loK5RXOpY2GGgiFxiqRlb59RK-l6_thumb.mp4",
      poster: imgHost + "loK5RXOpY2GGgiFxiqRlb59RK-l6_cover.jpg",
      name: "danTuiYinLa",
    },
    "zhiTuiTaiGao": {
      key: "zhiTuiTaiGao",
      title: "直腿抬高测试",
      demoUrl: vidHost + "lvuGZiM8W5XZrpnPWLMxJYGTSc0Y_thumb.mp4",
      poster: imgHost + "lvuGZiM8W5XZrpnPWLMxJYGTSc0Y_cover.jpg",
      name: "zhiTuiTaiGao",
    },
    "fuWoHouShenCeShi": {
      key: "fuWoHouShenCeShi",
      title: "俯卧后伸腿测试",
      demoUrl: vidHost + "luwNUyca6O4f3LiVHnwrm5WPAVhk_thumb.mp4",
      poster: imgHost + "luwNUyca6O4f3LiVHnwrm5WPAVhk_cover.jpg",
      name: "fuWoHouShenCeShi",
    },
    "zouLouZiShiCeMian": {
      key: "zouLouZiShiCeMian",
      title: "走路姿势侧面视频（15秒）",
      demoUrl: vidHost + "ls-tVGowsqV-PePxUQdxJslS3hZx_thumb.mp4",
      poster: imgHost + "ls-tVGowsqV-PePxUQdxJslS3hZx_cover.jpg",
      name: "zouLouZiShiCeMian",
    },
    "gongJianBuZhengMianCeMian": {
      key: "gongJianBuZhengMianCeMian",
      title: "弓箭步正面侧面视频（1个）",
      demoUrl: vidHost + "lkeQ1y8-ZrXEamXFLOfTnRuR-l41_thumb.mp4",
      poster: imgHost + "lkeQ1y8-ZrXEamXFLOfTnRuR-l41_cover.jpg",
      name: "gongJianBuZhengMianCeMian",
    },
    "shenDunZhengMianCeMian": {
      key: "shenDunZhengMianCeMian",
      title: "深蹲正面侧面视频（1个）",
      demoUrl: vidHost + "lqxfGRb543bPXS4qIfSedG8LxFmB_thumb.mp4",
      poster: imgHost + "lqxfGRb543bPXS4qIfSedG8LxFmB_cover.jpg",
      name: "shenDunZhengMianCeMian",
    },
    "ceWoGuSiTouJiLaShen": {
      key: "ceWoGuSiTouJiLaShen",
      title: "俯卧股四头肌拉伸测试",
      demoUrl: vidHost + "ltGQMxY3OvSRUqlyLfejeoemtV1j_thumb.mp4",
      poster: imgHost + "ltGQMxY3OvSRUqlyLfejeoemtV1j_cover.jpg",
      name: "ceWoGuSiTouJiLaShen",
    },
    "fuWoCheng": {
      key: "fuWoCheng",
      title: "俯卧撑测试（视频）",
      demoUrl: vidHost + "ls4OvsOJ0X3eVu_7cj9gzRw4sMzP_thumb.mp4",
      poster: imgHost + "ls4OvsOJ0X3eVu_7cj9gzRw4sMzP_cover.jpg",
      name: "fuWoCheng",
    },
    "jianWaiZhang": {
      key: "jianWaiZhang",
      title: "肩外展测试（视频）",
      demoUrl: vidHost + "lqEuNYVmAOQ7zAFTYaRVIv7K5jSV_thumb.mp4",
      poster: imgHost + "lqEuNYVmAOQ7zAFTYaRVIv7K5jSV_cover.jpg",
      name: "jianWaiZhang",
    },
    "jingCeQu": {
      key: "jingCeQu",
      title: "颈侧屈+旋转测试（视频）",
      demoUrl: vidHost + "ltgBvYf5enzO2h_R71Kx9tBe4eRy_thumb.mp4",
      poster: imgHost + "ltgBvYf5enzO2h_R71Kx9tBe4eRy_cover.jpg",
      name: "jingCeQu",
    },
    "tuoMaSi": {
      key: "tuoMaSi",
      title: "托马斯测试",
      demoUrl: vidHost + "lkr2dO8qWcnAOGkWy7PkI04Cqjth_thumb.mp4",
      poster: imgHost + "lkr2dO8qWcnAOGkWy7PkI04Cqjth_cover.jpg",
      name: "tuoMaSi",
    },
  };

  if (assess.pageTemplate === "YAOBU_V1") {
    testList = [
      allTestList.danTuiTunQiaoTest,
      allTestList.ceQiaoTest,
    ];

    uploadVideoList = [
      allVideoList.zuoZiXiongZhuiXuanZhuan,
      allVideoList.danTuiYinLa,
      allVideoList.zhiTuiTaiGao,
      allVideoList.fuWoHouShenCeShi
    ];
  } else if (assess.pageTemplate === "ZUBU_V1") {
    testList = [
      allTestList.tuiWeiDuTest,
      allTestList.jiaoHuaiBeiQuTest,
      allTestList.qianShenJuLiTest,
      allTestList.ceQiaoTest,
      allTestList.tunQiaoTest,
    ];

    uploadVideoList = [
      allVideoList.zouLouZiShiCeMian,
      allVideoList.gongJianBuZhengMianCeMian,
      allVideoList.shenDunZhengMianCeMian,
      allVideoList.ceWoGuSiTouJiLaShen,
    ];
  } else if (assess.pageTemplate === "JIANJING_V1") {
    testList = [
      allTestList.ceQiaoTest,
      allTestList.fuWoChengTest,
      allTestList.kouJianJuLiTest,
    ];

    uploadVideoList = [
      allVideoList.fuWoCheng,
      allVideoList.jianWaiZhang,
      allVideoList.jingCeQu,
    ];
  } else if (assess.pageTemplate === "QUANSHEN_V1") {
    testList = [
      allTestList.tuiWeiDuTest,
      allTestList.ceQiaoTest,
      allTestList.tunQiaoTest,
      allTestList.danTuiZhiChengTest,
    ];

    uploadVideoList = [
      allVideoList.zouLouZiShiCeMian,
      allVideoList.shenDunZhengMianCeMian,
      allVideoList.tuoMaSi,
    ];
  }

  if (assess.requiredTestList && assess.requiredTestList.length > 0) {
    testList = assess.requiredTestList
        .filter(item => allTestList.hasOwnProperty(item))
        .map(item => allTestList[item]);
  }

  if (assess.requiredVideoList && assess.requiredVideoList.length > 0) {
    uploadVideoList = assess.requiredVideoList
        .filter(item => allVideoList.hasOwnProperty(item))
        .map(item => allVideoList[item]);
  }

  const myImg = src => <img src={src} className="assess-result-img" alt=""/>;

  const {getFieldError, getFieldDecorator, getFieldProps} = props.form;
  var showAssessStatus = (loadStatus === "LOADED" && assess.status !== "PENDING" && assess.status !== "REJECTED");
  var showWarning = (loadStatus === "FAILED");
  var showLoading = (loadStatus === "NONE" || loadStatus === "LOADING");
  var showForm = (!showAssessStatus && !showWarning && !showLoading);
  var showSaveDraft = (loadStatus === "LOADED" && (assess.status === "PENDING" || assess.status === "REJECTED"));

  const ageList = [{
    label: "10岁及以下",
    value: "10"
  }];
  for (var i = 0; i < 49; i++) {
    ageList.push({
      label: `${i + 11}岁`,
      value: `${i + 11}`
    });
  }
  ageList.push({
    label: "60岁及以下",
    value: "60"
  });
  const painList = [{
    label: "无疼痛",
    value: "0",
  }, {
    label: "左侧疼痛",
    value: "1",
  }, {
    label: "右侧疼痛",
    value: "2",
  }, {
    label: "双侧疼痛",
    value: "3",
  }];

  return (
    <div>
      <div style={{display: showWarning ? "block" : "none"}}>
        <Result
          img={myImg(IMG_WARNING)}
          title="数据加载失败"
          message={<div className="assess-result-message">请刷新页面重试</div>}
        />
        <div className="assess-result-button" onClick={onRetry}>刷新重试</div>
      </div>
      <div style={{display: showLoading ? "block" : "none"}}>
        <div className="assess-loading-text">数据加载中...</div>
      </div>
      <div style={{display: showAssessStatus ? "block" : "none"}}>
        <Result
          img={myImg(IMG_WAITING)}
          title="正在评估"
          message={<div className="assess-result-message">请耐心等待评估结果</div>}
        />
      </div>
      <div style={{display: showForm ? "block" : "none"}}>
        <div className="assess-section-tips">
          <div className="assess-service-info">
            <img src={IMG_INFO} alt="" className="assess-section-tips-icon">
            </img>
            请仔细填写资料信息，如有疑问联系助手微信 {assess.serviceWechat}
          </div>
          <div className="assess-section-rejected-info"
               style={{display: assess.status === "REJECTED" ? "block" : "none"}}
               dangerouslySetInnerHTML={{__html: ("资料不符合要求，请修改后再提交：\n" + assess.rejectReason).replace(/\n/g, "<br />")}}
          >
          </div>
        </div>
        <div className="assess-section-title">注意事项</div>
        <div className="assess-test-wrapper" key="introVideo">
          <video
            controls
            controlsList={"nodownload nofullscreen"}
            disablePictureInPicture
            playsInline
            x5-playsinline
            webkit-playsinline="true"
            src="https://syh-video.shareyourhealth.cn/lv4vVuN2sTmGsWQW9CqOyUpiJBnc_thumb.mp4"
            poster="https://syh-img.shareyourhealth.cn/lv4vVuN2sTmGsWQW9CqOyUpiJBnc_cover.jpg">
          </video>
        </div>
        <div className="assess-section-sep"></div>

        <div className="assess-section-title">填写基本资料</div>
        <div>
          {getFieldDecorator('name', {
            initialValue: assessData.name,
            rules: [{
              type: 'string',
              required: true,
              message: '请输入称呼',
            }],
          })(
            <TextInputComp title="称呼" placeholder="请输入">
            </TextInputComp>
          )}
          <div className="assess-input-error">{getFieldError('name')}</div>
        </div>
        <div>
          {getFieldDecorator('wechatId', {
            initialValue: assessData.wechatId,
            rules: [{
              type: 'string',
              required: true,
              message: '请输入您的微信号',
            }],
          })(
            <TextInputComp title="微信号" placeholder="请输入您的微信号">
            </TextInputComp>
          )}
          <div className="assess-input-error">{getFieldError('wechatId')}</div>
        </div>
        <div>
          <TextInputComp title="职业" placeholder="请输入"
                         {...getFieldProps('career', {
                           initialValue: assessData.career,
                           rules: [{
                             type: 'string',
                             required: true,
                             message: '请输入职业',
                           }],
                         })}
          >
          </TextInputComp>
          {(getFieldError('career') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('career') || []).join(', ')}</div> : null
          }
        </div>
        <div>
          <PickerComp title="年龄" placeholder="请选择" data={ageList}
                      {...getFieldProps('age', {
                        initialValue: assessData.age,
                        rules: [{
                          type: 'string',
                          required: true,
                          message: '请选择年龄',
                        }],
                      })}
          >
          </PickerComp>
          {(getFieldError('age') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('age') || []).join(', ')}</div> : null
          }
        </div>
        <div>
          <TextInputComp title="身高（厘米）" placeholder="请输入"
                         type="number"
                         {...getFieldProps('height', {
                           initialValue: assessData.height,
                           rules: [{
                             type: 'string',
                             required: true,
                             message: '请输入身高',
                           }],
                         })}
          >
          </TextInputComp>
          {(getFieldError('height') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('height') || []).join(', ')}</div> : null
          }
        </div>
        <div>
          <TextInputComp title="体重（公斤）" placeholder="请输入"
                         type="number"
                         {...getFieldProps('weight', {
                           initialValue: assessData.weight,
                           rules: [{
                             type: 'string',
                             required: true,
                             message: '请输入体重',
                           }],
                         })}
          >
          </TextInputComp>
          {(getFieldError('weight') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('weight') || []).join(', ')}</div> : null
          }
        </div>
        <div className="assess-section-sep"></div>
        <div className="assess-section-title">填写问题描述</div>
        <div className="assess-section-subtitle">
          <div>1.首次疼痛的发生情况和可能原因？</div>
          <div>2.疼痛的具体位置和不舒服的具体感觉是如何的？</div>
          <div>3.在什么情况下会加重不舒服？</div>
        </div>
        <div className="assess-section-desc">
          例如：上下楼梯膝盖疼痛加重、久坐腰痛加重、症状不明显等、这种情况持续的时间，是否反复？
        </div>
        <div className="assess-section-line"></div>
        <div>
          <TextArea
            placeholder="请输入"
            style={{padding: '16px'}}
            rows={8}
            {...getFieldProps('userComplaint', {
              initialValue: assessData.userComplaint,
              rules: [{
                type: 'string',
                required: true,
                message: '请输入问题描述',
              }],
            })}
          >
          </TextArea>
          {(getFieldError('userComplaint') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('userComplaint') || []).join(', ')}</div> : null
          }
        </div>
        <div style={{ color: '#fff', height: '16px'}}></div>
        <div style={{ color: '#fff', height: '16px'}}></div>

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">上传体态照片</div>
        <div className="assess-section-desc">
          为保证评估结果准确性，请务必按照示范要求上传照片。介意者可遮挡面部。
        </div>
        <div className="assess-image-list">
          <div className="assess-image-item">
            <img src={IMG_FRONT_DEMO} alt=""></img>
          </div>
          <div className="assess-image-item">
            <img src={IMG_BACK_DEMO} alt=""></img>
          </div>
          <div className="assess-image-item">
            <img src={IMG_SIDE_DEMO} alt=""></img>
          </div>
        </div>
        <div style={{ color: '#fff', height: '16px'}}></div>
        <div className="assess-image-list">
          <div className="assess-upload-item">
            {getFieldDecorator('userFrontImage', {
              initialValue: assessData.userFrontImage,
              rules: [{
                validator: (rule, value) => {
                  const errors = [];
                  if (value && value.qiniuKey) {
                    return errors;
                  }
                  errors.push("请上传正面照");
                  return errors;
                },
              }],
            })(
              <UploadComp
                accept="image/*"
                title="上传正面照"
                beforeUpload={checkImageSize}
                getUploadToken={getPicToken}
              ></UploadComp>
            )}
          </div>
          <div className="assess-upload-item">
            <UploadComp
              accept="image/*"
              title="上传背面照"
              beforeUpload={checkImageSize}
              getUploadToken={getPicToken}
              {...getFieldProps('userBackImage', {
                initialValue: assessData.userBackImage,
                rules: [{
                  validator: (rule, value) => {
                    const errors = [];
                    if (value && value.qiniuKey) {
                      return errors;
                    }
                    errors.push("请上传背面照");
                    return errors;
                  },
                }],
              })}
            ></UploadComp>
          </div>
          <div className="assess-upload-item">
            <UploadComp
              accept="image/*"
              title="上传侧面照"
              beforeUpload={checkImageSize}
              getUploadToken={getPicToken}
              {...getFieldProps('userSideImage', {
                initialValue: assessData.userSideImage,
                rules: [{
                  validator: (rule, value) => {
                    const errors = [];
                    if (value && value.qiniuKey) {
                      return errors;
                    }
                    errors.push("请上传侧面照");
                    return errors;
                  },
                }],
              })}
            ></UploadComp>
          </div>
        </div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div className="assess-input-error">
          {(getFieldError('userFrontImage') || []).join(', ')}
        </div>
        <div className="assess-input-error">
          {(getFieldError('userBackImage') || []).join(', ')}
        </div>
        <div className="assess-input-error">
          {(getFieldError('userSideImage') || []).join(', ')}
        </div>
        <div style={{ color: '#fff', height: '24px'}}></div>

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">填写测试数据</div>
        <div className="assess-section-desc">
          请按照动作示范进行动作测试，并记录下时间，填写在下方。
        </div>
        {testList.map((test, tIndex) => (
          <div className="assess-test-wrapper" key={test.key}>
            <div className="assess-test-title">{`${tIndex + 1}. ${test.title}`}</div>
            <video
                controls
                controlsList={"nodownload nofullscreen"}
                disablePictureInPicture
                playsInline
                x5-playsinline
                webkit-playsinline="true"
                src={test.demoUrl}
                poster={test.poster}>
            </video>
            {(assess.pageTemplate !== "JIANJING_V1") && !(test.hidePain || false) ?
              <div className="assess-test-input-wrapper" key={test.key + "_pain"}>
                <PickerComp title="是否出现疼痛" placeholder="请选择" data={painList}
                            {...getFieldProps(test.key + 'Pain', {
                              initialValue: assessData[test.key + 'Pain'] || '0',
                              rules: [{
                                type: 'string',
                                required: true,
                                message: '请选择是否出现疼痛',
                              }],
                            })}
                >
                </PickerComp>
                {(getFieldError(test.key + 'Pain') || []).join(', ') !== '' ?
                  <div className="assess-input-error">{(getFieldError(test.key + 'Pain') || []).join(', ')}</div> : null
                }
              </div> : null}
            {test.key !== "tuiWeiDuTest" ? test.inputList.map((tInput) => (
              <div className="assess-test-input-wrapper" key={test.key + "_" + tInput.name}>
                <TextInputComp
                  type={tInput.type || "number"}
                  title={tInput.title}
                  placeholder={tInput.placeholder || "请输入"}
                  {...getFieldProps(tInput.name, {
                    initialValue: assessData[tInput.name],
                    rules: [{
                      type: 'string',
                      required: true,
                      message: '请输入' + tInput.title,
                    }],
                  })}
                >
                </TextInputComp>
                {(getFieldError(tInput.name) || []).join(', ') !== '' ?
                  <div className="assess-input-error">{(getFieldError(tInput.name) || []).join(', ')}</div> : null
                }
              </div>
            )) : null}
            {test.key === "tuiWeiDuTest" ? <table className="assess-test-table">
              <thead>
                <tr>
                  <th style={{width: '20%'}}>位置</th>
                  <th>左腿</th>
                  <th>右腿</th>
                </tr>
              </thead>
              <tbody>
                {test.inputList.map((tInput) => (
                  <tr key={tInput.title}>
                    <td key={"td_" + tInput.title}>{tInput.title}</td>
                    {tInput.inputItems.map((tItem, index) => (
                        <td key={tItem.name}>
                          <Input
                              placeholder='点击输入'
                              style={{ '--text-align': 'center' }}
                              {...getFieldProps(tItem.name, {
                                initialValue: assessData[tItem.name],
                                rules: [{
                                  type: 'string',
                                  message: tItem.placeholder,
                                }],
                              })}
                          />
                        </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table> : null}
          </div>
        ))}

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">上传测试视频</div>
        <div className="assess-section-desc">
          请按照动作示范进行动作练习，并录下视频上传。
        </div>
        {uploadVideoList.map((uVideo, vIndex) => (
          <div className="assess-upload-video-wrapper" key={uVideo.key}>
            <div className="assess-upload-video-title">{`${vIndex + 1}. ${uVideo.title}`}</div>
            <div className="assess-upload-video-input-wrapper">
              <div className="assess-upload-video-input-demo">
                <video
                  controls
                  controlsList={"nodownload nofullscreen"}
                  disablePictureInPicture
                  playsInline
                  x5-playsinline
                  webkit-playsinline="true"
                  src={uVideo.demoUrl}
                  poster={uVideo.poster}
                ></video>
              </div>
            </div>
            {assess.pageTemplate !== "JIANJING_V1" ?
                <div className="assess-upload-video-pain" key={uVideo.key + "_pain"}>
                  <PickerComp title="是否出现疼痛" placeholder="请选择" data={painList}
                              {...getFieldProps(uVideo.name + 'Pain', {
                                initialValue: assessData[uVideo.name + 'Pain'] || '0',
                                rules: [{
                                  type: 'string',
                                  required: true,
                                  message: '请选择是否出现疼痛',
                                }],
                              })}
                  >
                  </PickerComp>
                  {(getFieldError(uVideo.name + 'Pain') || []).join(', ') !== '' ?
                      <div
                          className="assess-input-error">{(getFieldError(uVideo.name + 'Pain') || []).join(', ')}</div> : null
                  }
                </div> : null
            }
            <div className="assess-upload-video-input-wrapper">
              <div className="assess-upload-video-input-item">
                <UploadComp
                  accept="video/*"
                  title="上传视频"
                  beforeUpload={checkVideoSize}
                  getUploadToken={getVideoToken}
                  {...getFieldProps(uVideo.name, {
                    initialValue: assessData[uVideo.name],
                    rules: [{
                      validator: (rule, value) => {
                        const errors = [];
                        if (value && value.qiniuKey) {
                          return errors;
                        }
                        errors.push("请上传视频");
                        return errors;
                      },
                    }],
                  })}
                ></UploadComp>
              </div>
            </div>
            {(getFieldError(uVideo.name) || []).join(', ') !== '' ?
              <div className="assess-input-error" style={{margin: '14px 14px 0px 14px'}}>
                {(getFieldError(uVideo.name) || []).join(', ')}
              </div> : null
            }
          </div>
        ))}

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">其他补充（选填）</div>
        <div className="assess-section-desc">
          首次疼痛、确诊时间、做过哪些治疗和康复手段、是否反复。
        </div>
        <div className="assess-section-line"></div>
        <TextArea
            placeholder="请输入"
            rows={8}
            style={{padding: '16px'}}
            {...getFieldProps('userRemark', {
              initialValue: assessData.userRemark,
              rules: [{
                type: 'string',
                message: '请输入',
              }],
            })}
        >
        </TextArea>

        <div>
          <div className="assess-section-sep"></div>
          <div className="assess-section-title">上传诊断结果报告单（选填）</div>
          <div className="assess-image-list">
            <div className="assess-upload-item">
              <UploadComp
                accept="image/*"
                title="上传报告单"
                beforeUpload={checkImageSize}
                getUploadToken={getPicToken}
                {...getFieldProps('userZhenDuanBaoGao1', {
                  initialValue: assessData.userZhenDuanBaoGao1,
                })}
              ></UploadComp>
            </div>
            <div className="assess-upload-item">
              <UploadComp
                accept="image/*"
                title="上传报告单"
                beforeUpload={checkImageSize}
                getUploadToken={getPicToken}
                {...getFieldProps('userZhenDuanBaoGao2', {
                  initialValue: assessData.userZhenDuanBaoGao2,
                })}
              ></UploadComp>
            </div>
            <div className="assess-upload-item">
              <UploadComp
                accept="image/*"
                title="上传报告单"
                beforeUpload={checkImageSize}
                getUploadToken={getPicToken}
                {...getFieldProps('userZhenDuanBaoGao3', {
                  initialValue: assessData.userZhenDuanBaoGao3,
                })}
              ></UploadComp>
            </div>
          </div>
        </div>

        <div style={{ color: '#fff', height: '16px'}}></div>
        <div style={{ color: '#fff', height: '16px'}}></div>

        <div className="assess-section-sep"></div>
        <div className="assess-submit-wrapper">
          <div className="assess-submit-tips">
            1. 评估表只能提交一次，确认提交后无法修改，请认真填写。评估视频，照片背景应避免杂乱，尽量调试好清晰完整的拍摄角度。<br/>
            2. 请尽量穿着贴身运动衣服，短裤背心为佳，光脚。<br/>
            3. 提交后，1-3个工作日完成评估报告（不包含周六周末，法定节假日）<br/>
            4. 填写时任何紧急问题，请联系客服微信：dankejiankang02<br/>
            5. 评估测试中的动作，谨记无痛原则，产生疼痛的测试可以暂时不做，联系客服进行反馈。<br/>
            6. 评估预约下单后，即进入评估流程，不接受退款。<br/>
          </div>
          <div className="assess-submit-btn" onClick={onSubmit}>提交</div>
        </div>
      </div>
      <div
        onClick={onSaveDraft}
        className="assess-save-draft"
        style={{display: showSaveDraft ? "block" : "none"}}
      >
        保存草稿
      </div>
    </div>
  );
};

export default createDOMForm()(FootAssessPage);
