import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";

import PageHome from './pages/home';
import RegisterPage from './pages/register';
import InvitePage from './pages/invite';
import FootAssessPage from './pages/footAssess';
import FootResultPage from './pages/footResult';
import OfflineAssessPage from './pages/footAssess/offlineAssess';
import OfflineResultPage from './pages/footResult/offlineResult';
import ScoliosisAssessPage from './pages/footAssess/scoliosisAssess';
import ScoliosisResultPage from './pages/footResult/scoliosisResult';
import HealthStagePage from './pages/health/stageIntro';
import AssessFAQPage from "./pages/fitness/assessFaq";
import ProjectionFAQPage from "./pages/fitness/projectionFaq";
import ScoliosisTypePage from "./pages/health/scoliosis/typeIntro"
import ScoliosisAssessIntroPage from "./pages/health/scoliosis/assessIntro"
function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={PageHome} />
        <Route path="/register/:code" component={RegisterPage} />
        <Route path="/invite" component={InvitePage} />
        <Route path="/assess/submit/:assessId" component={FootAssessPage} />
        <Route path="/assess/result/:assessId" component={FootResultPage} />
        <Route path="/assess/offline/submit/:assessId" component={OfflineAssessPage} />
        <Route path="/assess/offline/result/:assessId" component={OfflineResultPage} />
        <Route path="/assess/scoliosis/submit/:assessId" component={ScoliosisAssessPage} />
        <Route path="/assess/scoliosis/result/:assessId" component={ScoliosisResultPage} />
        <Route path="/health/stage/intro" component={HealthStagePage} />
        <Route path="/fitness/assess/faq" component={AssessFAQPage} />
        <Route path="/fitness/projection/faq/:pageIndex" component={ProjectionFAQPage} />
        <Route path="/fitness/projection/faq/:pageIndex" component={ProjectionFAQPage} />
        <Route path="/health/scoliosis/types" component={ScoliosisTypePage} />
        <Route path="/health/scoliosis/assessintro" component={ScoliosisAssessIntroPage} />
      </div>
    </Router>
  );
}

export default App;
