import * as Request from '../common/request';

export const getAssess = async (assessId, headers) => {
  let settings = {
    url: "/api/v1/health/assess/detail/" + assessId,
    data: {},
    hideError: true,
    headers,
  };

  return await Request.get(settings);
};

export const submitAssess = async (params, headers) => {
  let settings = {
    url: "/api/v1/health/assess/submit",
    data: params,
    hideError: true,
    headers,
  };

  return await Request.post(settings);
};

export const saveAssess = async (params, headers) => {
  let settings = {
    url: "/api/v1/health/assess/save",
    data: params,
    hideError: true,
    headers,
  };

  return await Request.post(settings);
};
