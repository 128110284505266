import * as Request from '../common/request';

export const getPicToken = async (headers) => {
  let settings = {
    url: "/api/v1/qiniu/pic-uptoken",
    data: {},
    hideError: true,
    headers,
  };

  return await Request.get(settings);
};

export const getVideoToken = async (headers) => {
  let settings = {
    url: "/api/v1/qiniu/video-uptoken",
    data: {},
    hideError: true,
    headers,
  };

  return await Request.get(settings);
};