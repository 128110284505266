import React, { useState, useEffect } from 'react';
import { Input, Button, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import * as inviteApi from '../../apis/inviteApi';

import DownloadAppModal from "./DownloadAppModal";
import './index.less';
import IMG_COUPON from './images/ic_coupon.png';
import IMG_DOWNLOAD from './images/ic_bot_download.png';

const RegisterPage = props => {

  const [page, setPage] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      var res = await inviteApi.getRegisterPage(props.match.params.code);
      if (res.errCode !== 0) {
        Toast.show({
          content: res.errMsg,
          icon: 'fail',
        });
      } else {
        setPage(res);
      }
    };
    fetchData();
  }, [props.match.params.code])

  const registerNow = (e) => {
    const { code } = props.match.params;

    props.form.validateFields(async (err, values) => {
      if (!err) {
        var res = await inviteApi.inviteMobile({
          inviteCode: code,
          countryCode: "86",
          mobile: values.phone.replace(/\s/g, ""),
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          devicePixelRatio: window.devicePixelRatio,
          systemVersion: window.navigator.userAgent,
          deviceType: "",
        });
        if (res.errCode !== 0) {
          Toast.show({
            content: res.errMsg,
            icon: 'fail',
          });
        } else {
          showModal(e);
        }
      }
    });
  }

  const [hideModal, setHideModal] = useState(true);
  const showModal = (e) => {
    e.preventDefault();
    setHideModal(false);
  }

  const onClose = () => {
    setHideModal(true);
  }

  const onDownload = () => {
    window.open(page.downloadUrl);
  }

  const { getFieldError, getFieldDecorator } = props.form;
  if (page.errCode === undefined || page.errCode !== 0) {
    return (
        <div>
          <p>{page.errMsg}</p>
        </div>
    );
  }

  return (
      <div>
        <DownloadAppModal
            hideModal={hideModal}
            onClose={onClose}
            onDownload={onDownload}
        />
        <section className="banner-wrap">
          <Carousel className="banner" {...{
            autoPlay: true,
            interval: 4000,
            emulateTouch: true,
            infiniteLoop: true,
            showArrows: false,
            dynamicHeight: true,
            showThumbs: false,
            showStatus: true,
            showIndicators: false,
            useKeyboardArrows: true,
            statusFormatter: (cur, total) => `${cur} / ${total}`
          }}>
            {page.banners.map(banner => <img key={banner} src={banner} alt="图片不存在" />)}
          </Carousel>
        </section>
        <div className="user-info">
        <span>
          <img className="avatar" src={page.avatarUrl} alt="图片不存在"></img>
        </span>
          <div className="info-wrapper">
            <span className="nickname">{page.nickname}</span>
            <span className="gift-info">送你一份优惠</span>
            <span className="beautiful">快来一起变瘦变美！</span>
          </div>
        </div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div className="item-wrapper">
          <img src={IMG_COUPON} alt="图片不存在"></img>
        </div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div className="item-wrapper">
          {getFieldDecorator('phone', {
            initialValue: '',
            rules: [{
              required: true,
              message: '请输入正确的手机号码',
              validator: (rule, value) => value.length === 13,
            }],
          })(
              <Input
                  type="phone"
                  placeholder="输入手机号码"
                  className="mobile-input"
              ></Input>
          )}
          <div className="error-tips">{getFieldError('phone')}</div>
        </div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div className="item-wrapper">
          <Button color="warning" onClick={registerNow}>立即领取</Button>
        </div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div className="item-wrapper">
          <section className='banner-wrapper'>
            <Carousel className="banner" {...{
              autoPlay: true,
              interval: 2500,
              emulateTouch: false,
              infiniteLoop: true,
              showArrows: false,
              dynamicHeight: false,
              showThumbs: false,
              showStatus: false,
              showIndicators: false,
              useKeyboardArrows: false,
              swipeable: false,
              axis: "vertical",
              verticalSwipe: "standard",
            }}>
              {(page.mobiles || []).map(mobile => <div key={mobile} className="scroll-mobile">
                <div className="nickname">{mobile}</div>
                <div className="gift-info">领取了</div>
                <div className="coupon-info">50元全身体态优惠券</div>
              </div>)}
            </Carousel>
          </section>
        </div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        {(page.botImages || []).map((botImg) => <div key={botImg}>
          <img className="intro-img" src={botImg} alt="图片不存在"></img>
        </div>)}
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div style={{ color: '#fff', height: '24px'}}></div>
        <div className="bot-download">
          <div className="download-content">
            <img src={IMG_DOWNLOAD} alt="下载App"></img>
            <div className="download-text">
              <div>打开 蛋壳跟练App</div>
              <div>一起变瘦变美</div>
            </div>
            <div className="download-btn" onClick={onDownload}>立即开启</div>
          </div>
        </div>
      </div>
  );
}

export default createForm()(RegisterPage);
