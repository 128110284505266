import React from 'react';
import {SafeArea, Tabs} from 'antd-mobile'
import DocumentTitle from 'react-document-title';

import './index.less';
import AssessFaqItem from '../assessFaq/faqItem';

const ProjectionFAQPage = (props) => {
  const tabList_ios = [
    {
      title: "付费计划视频",
      faqs: [
        {
          title: "第一步",
          mediaList: [
            {
              text: "请确认电视已打开，并且与你的手机连接的是同一个Wi-Fi网络。",
              imageUrl: "https://syh-img.shareyourhealth.cn/ic_projection_first.png",
              imageWidth: "180px",
            },
          ]
        }, {
          title: "第二步",
          mediaList: [
            {
              text: "点击视频右侧投屏按钮，搜索可投屏电视，选择连接即可。",
              imageUrl: "https://syh-img.shareyourhealth.cn/ic_projection_second_ios.png",
              imageWidth: "200px",
            }
          ]
        }, {
          title: "发现不了设备/连接不上?",
          mediaList: [
            {
              text: "方法1：\n\n在电视应用商店，搜索LBTP，安装乐播投屏TV版并且打开。\n\n" +
                "方法2：\n\n将乐播投屏TV版下载并拷贝到U盘，再将U盘插入电视或机顶盒上安装。\n\n",
            }
          ]
        }
      ],
    }, {
      title: "B站免费视频",
      faqs: [
        {
          title: "第一步",
          mediaList: [
            {
              text: "请确认电视已打开，并且与你的手机连接的是同一个Wi-Fi网络。",
              imageUrl: "https://syh-img.shareyourhealth.cn/ic_projection_first.png",
              imageWidth: "180px",
            },
          ]
        }, {
          title: "第二步",
          mediaList: [
            {
              text: "先点击视频“进入全屏模式”，点击左下角“投屏按钮”，采用隔空播放的形式进行。",
              imageUrl: "https://syh-img.shareyourhealth.cn/ic_projection_bilibili.png",
              imageWidth: "200px",
            }
          ]
        }, {
          title: "投屏卡/连接不上？",
          mediaList: [
            {
              text: "由于电视以及Wi-Fi网络宽带的问题，使用投屏时偶尔有卡顿的情况，属于正常。\n" +
                "另外，如若搜索不到，请确认该电视是否支持iOS系统的隔空播放功能。",
            }
          ]
        }
      ],
    }
  ];

  const tabList_android = [
    {
      title: "付费计划视频",
      faqs: [
        {
          title: "第一步",
          mediaList: [
            {
              text: "请确认电视已打开，并且与你的手机连接的是同一个Wi-Fi网络。",
              imageUrl: "https://syh-img.shareyourhealth.cn/ic_projection_first.png",
              imageWidth: "180px",
            },
          ]
        }, {
          title: "第二步",
          mediaList: [
            {
              text: "点击视频右侧投屏按钮，搜索可投屏电视，选择连接即可。",
              imageUrl: "https://syh-img.shareyourhealth.cn/ic_projection_second_ios.png",
              imageWidth: "200px",
            }
          ]
        }, {
          title: "发现不了设备/连接不上?",
          mediaList: [
            {
              text: "方法1：\n在电视应用商店，搜索LBTP，安装乐播投屏TV版并且打开。\n\n" +
                "方法2：\n将乐播投屏TV版下载并拷贝到U盘，再将U盘插入电视或机顶盒上安装。\n\n",
            }
          ]
        }
      ],
    }, {
      title: "B站免费视频",
      faqs: [
        {
          title: "",
          mediaList: [
            {
              text: "由于B站视频属于第三方平台，目前安卓版本无法支持投屏到电视（技术小哥哥正在积极研究解决方案）",
            },
          ]
        }
      ],
    }
  ];

  const renderTabContent = tab => (
      <Tabs.Tab title={tab.title} key={tab.title}>
        {(tab.faqs || []).map((faqItem, index) => (
            <AssessFaqItem key={`${tab.title}_${index}`} faq={faqItem}>
            </AssessFaqItem>
        ))}
      </Tabs.Tab>
  );

  const isAndroid = (navigator.userAgent.indexOf("android") !== -1) ||
    (navigator.userAgent.indexOf("Android") !== -1);
  const firstPage = props.match.params.pageIndex || '0';

  return (
    <DocumentTitle title={"投屏帮助"}>
      <div style={{paddingTop: '10px'}}>
        <SafeArea position='top' />
        <Tabs defaultActiveKey={firstPage !== '1' ? '付费计划视频' : 'B站免费视频'}>
          {(isAndroid ? tabList_android : tabList_ios).map(e => renderTabContent(e))}
        </Tabs>
        <SafeArea position='bottom' />
      </div>
    </DocumentTitle>
  );
};

export default ProjectionFAQPage;
