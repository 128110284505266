import React, { useState, useEffect } from 'react';
import { Toast } from 'antd-mobile'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import dsBridge from 'dsbridge';

import * as inviteApi from '../../apis/inviteApi';

import './index.less';
import IMG_HEADER from './images/ic_invite_bg.png';
import IMG_INVITE from './images/ic_invite_share.png';
import IMG_FOOTER from './images/ic_invite_footer.png';
import IMG_STEPS from './images/ic_invite_steps.png';

const InvitePage = props => {
  const [page, setPage] = useState({});

  useEffect(() => {
    const fetchData = async (data) => {
      var tokenInfo = JSON.parse(data);
      var res = await inviteApi.getInvitePage(tokenInfo);
      if (res.errCode !== 0) {
        Toast.show({
          icon: 'fail',
          content: res.errMsg
        });
      } else {
        setPage(res);
      }
    };

    dsBridge.call("invite.getToken", "", async (data) => {
      if (data) {
        fetchData(data);
      }
    });

    dsBridge.register('updateToken', function(data) {
      fetchData(data);
    });
  }, [])

  const onShareInvite = (e) => {
    e.preventDefault();
    dsBridge.call("invite.showInvitePannel", JSON.stringify(page.shareInfo));
  }

  const onWithdraw = (e) => {
    e.preventDefault();
    dsBridge.call("invite.showWithdrawPage");
  }

  return (
    <div>
      <div className="header-wrapper">
        <img src={IMG_HEADER} alt="图片不存在"></img>
        <div className="invite-wrapper">
          <img src={IMG_INVITE} alt="图片不存在" onClick={onShareInvite}></img>
          <section className='banner-wrapper'>
            {(page.inviteList && page.inviteList.length > 0) ? <Carousel className="banner" {...{
					    autoPlay: true,
					    interval: 2500,
					    emulateTouch: false,
					    infiniteLoop: true,
					    showArrows: false,
					    dynamicHeight: true,
					    showThumbs: false,
					    showStatus: false,
					    showIndicators: false,
              useKeyboardArrows: false,
              swipeable: false,
              axis: "vertical",
				    }}>
              {(page.inviteList || []).map(mobile => <div key={mobile} className="invite-list">
                <div className="invite-item">{mobile}</div>
              </div>)}
				    </Carousel> : null}
          </section>
        </div>
      </div>
      <div className="invite-stat-wrapper">
        <div className="stat-info">
          <div className="left-info">
            <div className="reward-title">现金红包</div>
            <div className="reward-amount">{page.stat ? page.stat.available.toFixed(2) : 0.00}</div>
            <div className="reward-total">累计获得 {page.stat ? page.stat.total : 0}</div>
          </div>
          <div className="right-info" onClick={onWithdraw}>红包提现</div>
        </div>
      </div>
      <div className="invite-steps-wrapper">
        <div className="step-bg"></div>
        <img src={IMG_STEPS} alt="图片不存在"></img>
      </div>
      <div className="invite-footer-wrapper">
        <img src={IMG_FOOTER} alt="图片不存在"></img>
      </div>
    </div>
  );
};

export default InvitePage;
