import React from "react";
import DocumentTitle from "react-document-title";

import "./index.less";

const ScoliosisTypePage = () => {
  const typeList = [
    {
      title: "① 3C - 骨盆中立，腰椎短弧度代偿",
      image:
        "https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_schroth_1.jpg",
      content:
        "也叫做3CN，此种类型的侧弯主弯也位于胸部，腰椎有一个代偿。通常弧度较短，且不超过中线，骨盆位于中立位置，第四腰椎到骶骨间的椎间盘没有明显变形，腰部和骨盆没有对推。",
    },
    {
      title: "② 3CH - 髋组",
      image:
        "https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_schroth_2.jpg",
      content:
        "此种类侧弯是第一个被描述的侧弯类型，主弯位于胸部，之所以叫做髋组是因髋部在胸凹侧突出明显,在骨盆的上方胸部凸向另一侧。",
    },
    {
      title: "③ 3CL - 骨盆中立,大弧度腰椎代偿",
      image:
        "https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_schroth_3.jpg",
      content:
        "此种类型的侧弯主弯也位于胸部，腰椎有一个代偿，但是通常弧度较长，越过了身体中线。腰部与骨盆没有对推，第四腰椎到骶骨之间的椎间盘相对平衡。但因为腰部代偿太大形成了双弧，所以此种类型的侧弯也按照功能性四弧做治疗，按照功能性三弧做治疗会增加腰部的侧凸。",
    },
    {
      title: "④ 3CTL - 胸腰段主弯组",
      image:
        "https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_schroth_4.jpg",
      content:
        "此种类型可以当作为3CH的另一种形式，只是原本位于胸部的主弯顶椎出现在了胸腰结合部，一般在第12胸椎。此分类只在学术上有一定的意义。",
    },
    {
      title: "⑤ 4C - 双弧",
      image:
        "https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_schroth_5.jpg",
      content:
        "典型的功能性四弧，第四腰椎到骶骨的椎间盘呈现楔形，腰部与骨盆完全对推到相反的方向，有两个主弯：一个在胸一个在腰。",
    },
    {
      title: "⑥ 4CL - 主弯腰椎",
      image:
        "https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_schroth_6.jpg",
      content:
        "X光片当中可见单弧，主弯在腰部，上下各有一个代偿，胸部代偿相对不明显，腰部与骨盆对推到相反方向，骨盆侧凸方向与胸凸一致。",
    },
    {
      title: "⑦ 4CTL - 主弯在胸腰段",
      image:
        "https://syh-img.shareyourhealth.cn/egghealthma/ic_instruction_schroth_7.jpg",
      content:
        "此种类型功能性四弧主弯位于胸腰部，但需要注意的是侧凸的顶椎一般位于第一腰椎，也有极少的人顶椎位于第十二胸椎和第一腰椎的椎间盘。骨盆与腰椎对推，骨盆侧凸的方向与胸椎侧凸的方向一致。如果顶椎位于第十二胸椎，一般归类为3CTL。",
    },
  ];

  return (
    <DocumentTitle title={"脊柱侧弯分型"}>
      <div className="stp">
        <div class="stp-header-main">
          <div class="stp-header-main-title">莱纳特-施罗德</div>
          <div class="stp-header-main-subTitle">脊柱侧弯的分型（ALS）</div>
          <div style={{ marginTop: "16px" }}>
            The Augmented Lehnert-schroth (ALS) classification
          </div>

          <div class="stp-header-main-sub">
            在定制矫正方案前，需要先将脊柱侧弯进行分型，然后再安排合理的训练方案。
          </div>
        </div>

        <div className="stp-types">
          {typeList.map((item) => (
            <div className="stp-types-item">
              <div className="stp-types-item-title">
                <span>{item.title}</span>
              </div>
              <div className="stp-types-item-img">
                <img src={item.image} alt=""></img>
                <div className="stp-types-item-img-desc">{item.content}</div>
              </div>
            </div>
          ))}
        </div>

        <div style={{ height: "80rpx" }}>
          <div class="stp-bottom">
            The Augmented Lehnert-schroth (ALS) classification
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default ScoliosisTypePage;
